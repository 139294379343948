import { IStudentTable, TTableColumns } from "../../Types/table.interface";

export const studentTable: TTableColumns<IStudentTable>[] = [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.firstName + " " + value.lastName,
  },
  {
    key: "email",
    label: "E-Mail",
    renderCell: (value) => value.email,
  },
  {
    key: "phone",
    label: "Phone Number",
    renderCell: (value) => {
      if (!value.mobile && !value.countryCode) {
        return "NA";
      }
      return `${value.countryCode}-${value.mobile}`;
    },
  },
  {
    key: "kyc",
    label: "Kyc status",
    renderCell: (value) => (value.isKycDone ? "Done" : "No"),
  },
  {
    key: "terms",
    label: "Terms & Conditions",
    renderCell: (value) => (value.isTermsAccepted ? "Done" : "No"),
  },
];
